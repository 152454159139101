import React from "react"
import disenoCss from "../../css/diseno.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// la imagen
import Branding from "../../components/images/branding/branding"
import MenuDiseno from "../../components/menuDiseno/menuDiseno"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const Marca = () => (
  <>
    <div className={disenoCss.background}>
      <div className={disenoCss.gradient}></div>
    </div>
    <Branding></Branding>
    <Link to="/" className={disenoCss.logoBlanco}>
      <img src={LogoBlanco} alt="Logotipo de engrane version blanca" />
    </Link>
    <MenuDiseno></MenuDiseno>
    <div className={disenoCss.container}>
      <Layout siteTitle="Diseño">
        <SEO title="Desarrollo Web" />
        <div className={disenoCss.contenido}>
          <h1 className={disenoCss.titulo}>
            be your
            <br />
            brand
          </h1>
          <p className={disenoCss.text}>
            ¿Te es complicado el saber cual es tu segmento o que acciones debes
            tomar para que tus clientes o posibles consumidores te ubiquen como
            marca? Nosotros te ayudamos a gestionar las estrategias necesarias
            para que tu marca pueda estar en la mente de tus clientes y así
            mismo el poderla posicionar.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={disenoCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default Marca
