import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import brandingCss from "./branding.module.css";

const Branding = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "branding.png" }) {
      childImageSharp {
        fixed(width: 1200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return <Img fixed={data.file.childImageSharp.fixed} className={brandingCss.Img}/>
}

export default Branding
